@import '../../styles/variables.scss';

.ui.popup.recent-search-list {
  width: 100%;
  padding: 0;
  min-width: 80px;

  .header + .content {
    padding-top: 0;
  }

  .header {
    font-size: 11px;
    font-weight: 500;
    color: $charcoal;
    padding: 12px;
    border-bottom-color: $silver;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .content {
    overflow: auto;
    max-height: 420px;

    ul.recent-terms {
      padding-inline-start: 0;
      margin: 0;
      li.item {
        list-style-type: none;
        position: relative;
        padding: 8px 40px 8px 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.14;
        color: $onyx;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 42px;
        cursor: pointer;

        &:hover {
          background-color: $fresh-air;
        }

        span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .text {
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.search-results {
  padding: 16px;
}

@import '../../styles/variables';

.add-ons-save-table-footer { 
  background-color: #f9fafb;
  .add-ons-save-table-comment-cell {

    .comment-input {
      .comment { 
        width: 100%;
        height: 20px;
      }
    }
  }

  .save-cell { 
    border: none !important;
  }
}
@import '../../styles/variables';

.feature-flag-stats {
  .header { 
    margin-bottom: 15px;
    button, h2 { 
      display: inline-block;
    }
    h2 {
      padding-left: 10px;
      margin-top: 0;
    }

    .ui.icon.back-button { 
      font-size: 23px;
      padding: 0;
      background: transparent;
    }
  }
}

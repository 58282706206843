@import '../../styles/variables';

.feature-flag-overrides {
  .header { 
    margin-bottom: 15px;
    button, h2 { 
      display: inline-block;
    }
    h2 {
      padding-left: 10px;
      margin-top: 0;
    }

    .ui.icon.back-button { 
      font-size: 23px;
      padding: 0;
      background: transparent;
    }
  }

  .feature-overrides-clients { 
    margin-top: 20px;

    .form-buttons { 
      margin-top: 20px;
    }
  }
}

.feature-flag-overrides-for-internal { 
  .override-value { 
    h4 { 
      margin-bottom: 2px;
    }
  }
 .skip-qa { 
    margin-top: 20px;
  }
}

@import '../../../styles/variables';

.account-details {
  background: $white-smoke;
  min-height: 100%;

  .logoWrapper {
    .logo {
      box-shadow: 5px 10px #888888;
    }
  }

  .ui.statistics {
    .statistic {
      .icon {
        margin-right: 8px;
        font-size: 54px;
      }
    }
  }

  .module {
    flex: 1;
  }

  .module.details-module {
    .body {
      padding: 16px;
    }
  }

  .module.account-info-module {
    flex: 8;

    .body {
      padding: 16px;
      height: 132px;
      padding-left: 132px;

      .company-name {
        margin: 0px;
        padding: 0px;
        color: $onyx;
        font-size: 22px;
      }

      .account-logo {
        left: 16px;
        width: 100px;
        height: 100px;
        position: absolute;

        img {
          //border-radius: 50%;
          box-shadow: 1px 1px 3px #888888;
        }

        .icon {
          position: relative;
          font-size: 100px;
          margin-top: 40px;
          margin-left: -9px;
          color: $granite;
        }
      }

      .clientId {
        color: $granite;
      }

      .stats {
        position: absolute;
        right: 16px;
        bottom: 8px;
        display: flex;

        .stat {
          position: relative;
          padding-left: 24px;
          margin-left: 24px;

          .ui.icon {
            position: absolute;
            left: 0px;
            top: 12px;
            font-size: 14px;
          }

          .title {
            font-weight: bold;
            color: $onyx;
          }

          .value {
            color: $charcoal;
          }
        }
      }
    }
  }

  .module.timeline-module {
    .body {
      padding: 0 0 0 8px;
    }
  }
}

@import '../../styles/variables';

.release-environment-pill {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  background-color: $sky-blue;
  border-color: $onyx;
  border-width: 2px;
  padding: 10px 20px;
  border-radius: 16px;
  width: 300px;
  margin-right: 10px;
  &.failed { 
    background-color: $rusty-red;
  }

  &.deploying { 
    background-color: $fresh-air;
  }
  

  .deploying-loader { 
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    .loader { 
      margin-right: 5px;
    }
  }
  .version { 
    font-size: 14px;
  }
  .deployed-by {
    font-size: 12px;
    margin-left: 5px;
  }

  .deployed-on { 
    font-size: 14px;
  }
}

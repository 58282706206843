.update-subscription {
  .ui.button {
    background-color: #2185d0;
    color: white;

    &:hover {
      background-color: #1678c2;
    }
  }

  .error-message {
    margin-bottom: 16px;
  }
}

@import '../../styles/variables';

.feature-flag-experiments-list {

  .header { 
    .ui.search>.results {
      width: fit-content;
    }
  }

  tr { 
    cursor: pointer;
  }
}

.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  .error-content {
    .errorguy {
      background-image: url('./errorguy.svg');
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      margin: auto;
    }

    .footer {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 20px;

      .text {
        line-height: 30px;
        font-size: 18px;
        max-width: 800px;
      }
    }
  }
}

@import '../../../styles/variables.scss';

.account-features {
  padding: 16px;

  .page-header { 
    h3 { 
      margin-bottom: 2px;
    }
    .subtext { 
      font-style: italic;
    }

    .search-header { 
      margin-top: 10px;
      margin-bottom: 15px;
      width: 300px;
    }
  }

  .features-list {
    min-width: 600px;
    width: fit-content;

    .client-override-value { 
      background-color: $sunglow;
    }

    .update-value { 
      background-color: $rusty-red;
    }
    .delete-override {
      .is-default { 
        visibility: hidden;
      }
    }
    .comment { 
      width: 100%;
      height: 75px
    }
  }

  .icon.info.circle {
    cursor: pointer;
    margin-left: 4px;
    color: #777;
  }
}

.delete-override-modal { 
  .description { 
    margin-bottom: 20px;
    span { 
      display: block;
      margin-bottom: 3px;
    }
  }
  .ui.form.field { 
    .comment { 
      width: 100%;
      height: 30px
    }
  }
  
}

.subscriptions {
  padding: 16px;
  .ui.menu {
    position: unset; // Override the absolute position of the menu
  }

  .page-header { 
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    .ui.search>.results {
      width: fit-content;
    }
    .header-item { 
      flex-grow: 1;
    }
  }

  .page-container { 
    display: flex;
    flex-direction: row;

    .page-menu { 
      margin-top: 41px;
    }

    .page-content { 
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}

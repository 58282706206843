@import '../../styles/variables';

.account {
  padding: 16px;
  background: $white-smoke;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .logoWrapper {
    .logo {
      box-shadow: 5px 10px #888888;
    }
  }

  .ui.statistics {
    .statistic {
      .icon {
        margin-right: 8px;
        font-size: 54px;
      }
    }
  }

  .account-info {
    position: relative;
    padding: 16px;
    height: 132px;
    padding-left: 132px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    min-width: 300px;

    .company-name {
      margin: 0px;
      padding: 0px;
      color: $onyx;
      font-size: 22px;
    }

    .account-logo {
      left: 16px;
      width: 100px;
      height: 100px;
      position: absolute;

      img {
        box-shadow: 1px 1px 3px #888888;
      }

      .icon {
        position: relative;
        font-size: 100px;
        margin-top: 40px;
        margin-left: -9px;
        color: $granite;
      }
    }

    .clientId {
      color: $granite;
    }

    .form-usage {
      position: absolute;
      right: 16px;
      top: 16px;
      display: flex;

      .warning {
        color: orange;
      }
      
      .error {
        color: red;
      }
    }

    .stats {
      position: absolute;
      right: 16px;
      bottom: 8px;
      display: flex;

      .stat {
        position: relative;
        padding-left: 24px;
        margin-left: 24px;

        .ui.icon {
          position: absolute;
          left: 0px;
          top: 12px;
          font-size: 14px;
        }

        .title {
          font-weight: bold;
          color: $onyx;
        }

        .value {
          color: $charcoal;
        }
      }
    }
  }

  .nav-wrapper {
    position: relative;
    flex: 1;
    display: flex;

    .account-menu {
      position: relative;
      width: 200px;
      padding-top: 16px;

      .nav-link {
        display: block;
        padding: 4px;

        &:hover {
          background: $fresh-air;
        }

        &.active {
          background: $sky-blue;
        }
      }
    }

    .body-wrapper {
      position: relative;
      flex: 1;
      margin-top: 16px;
      margin-left: 16px;

      .settings > .modules {
        flex-direction: column;

        .module > .body > .delete-info {
          margin-left: 8px;
        }
      }
    }
  }

  .pending{
    color: crimson;
    font-weight: Bold;
  }
}

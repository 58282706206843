@import '../../../styles/variables';

.timeline {
  background: #fff;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;

  .timeline-item {
    position: relative;
    padding: 4px 0;

    &:before {
      content: '';
      width: 1px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 18px;
      background: $silver;
    }

    &.positive {
      i.icon {
        color: rgba(green, 0.4);
      }
    }

    &.negative {
      i.icon {
        color: $rusty-red-40;
      }
    }

    &.neutral {
      i.icon {
        color: #999;
      }
    }

    i.icon {
      position: absolute;
      font-size: 24px;
      top: 10px;
      left: 4px;
      background: #fff;
      height: 21px;

      &:before {
        margin-top: 2px;
        display: inline-block;
      }
    }

    .item-body {
      margin-left: 40px;

      .timestamp {
        color: #777;
      }
    }
  }
}

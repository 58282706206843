@import '../../../styles/variables';

.metrics {
  padding: 16px;
  background: $white-smoke;
  min-height: 100%;

  .control-bar {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
  }

  .controls {
    max-width: 500px;
    display: flex;
    flex-direction: row;
  }

  .control {
    margin-right: 20px;
  }
}

@import '../../styles/variables';

.client-add-ons-table-row {

  .client-add-ons-table-row-value { 
   
    &.add-on-id { 
      width: 15%;
    }

    &.add-on-pricing-type { 
      width: 10%;
    }
    
    &.add-on-price { 
      width: 10%;
    }

    &.add-on-user-limit { 
      width: 10%;
    }

    &.add-on-users-assigned { 
      width: 10%;
    }

    &.add-on-unsubscribed-date { 
      width: 15%;
    }
   
    &.add-on-user-unsubscribed-date { 
      width: 15%;
    }
    
    &.add-on-metadata {
      i { 
        cursor: pointer;
      }
    }

    .client-add-on-dropdown {
      width: 2vw;
    }
  }
}
.add-ons-dates { 
  .add-ons-dates-section { 
    padding-bottom: 10px;
    label { 
      font-weight: bold;
      display: block;
    }
    span { 
      display:  block;
    }
  }
}
@import '../../styles/variables';

.edit-feature-flag {
  padding: 8px;
  background: $white-smoke;
  .page-header {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    margin: 20px 0;

    .feature-info { 
      .back-button { 
        display: inline-block;
        margin: 0 15px;
        font-size: 23px;
        padding: 0;
        background: transparent;
      }
      .feature-title { 
        display: inline-block;
        margin: 0px;
        padding: 0px;
        color: $onyx;
        font-size: 22px;
      }
      .last-update { 
        color: $granite;
        padding-left: 15px;
      }
      .featureId { 
        color: $granite;
        padding-left: 15px;
      }
      .stats {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 5px;
  
        .stat {
          margin-right: 15px;
          margin-left: 10px;
          color: $charcoal;
          .value { 
            i { 
              padding-right: 10px;
            }
            font-size: 22px !important;
          }
          .label { 
            font-size: 12px;
          }
        }
      }
    }
  }

  .page-body {
    margin: 0 8px;
    padding-left: 0;
    background: $white-smoke;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    & > div { 
      margin: 0 8px;
      padding: 16px;
      background: #fff;
      box-shadow: 0 1px 2px #ccc;
      border-radius: .285rem;
    }
    .menu-view {
      width: 200px;

      a.nav-link { 
        color: $onyx;
        font-size: 16px;
        font-weight: bold;
        display: block;
        padding: 12px;

        &:hover {
          background: $fresh-air;
        }

        &.active {
          background: $sky-blue;
        }
      }
    }

    .content-view {
      flex: 1;
      overflow: auto;
    }
  }
}

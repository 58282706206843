@import '../../styles/variables';

.jira-release-search {

  .header { 
    margin-bottom: 10px;

    .ui.button.floating.labeled.search.dropdown.icon { 
      color: $white;
      background-color: $atomic-orange;
    }
  }
}
@import '../../styles/variables';

.release-connector {
  color: rgb(85, 163, 98);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  column-gap: 10px;
}
@import '../../styles/variables';

.feature-flag-details {
  
  .ff-body {

    .ff-field-group { 
      display: flex;
      flex-basis: max-content;
      flex-direction: row;
      gap: 15px;
    }

    .ff-field-value { 
      margin-bottom: 12px;
      label { 
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin-bottom: 3px;
        color: $onyx;
      }

      label.description { 
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
      }

      &.ff-name, &.ff-code-name { 
        label + div { 
          min-width: 300px;
        }
      }

      &.ff-category { 
        label + div { 
          min-width: 200px;
        }
      }

      &.ff-client-controlled { 
        label + div { 

        }
      }

      &.ff-default-value { 
        label + div { 
          display: flex;
          flex-direction: row;
          gap: 15px;

          .data-type { 
            width: 100px;
            height: 38px;
          }
          .feature-flag-value { 
            width: 100%;
          }
        }
      }

      &.ff-read-groups { 
        width: fit-content;
      }

      &.ff-write-groups { 
        width: fit-content;
      }

      &.ff-subscription-defaults { 
        width: 800px;
      }

      &.ff-promo-code-defaults { 
        width: 800px;
      }
    }

    .form-buttons { 
      text-align: right;
      .ui.negative.button { 
        margin-right: 10px;
      }
    }

    .table { 
      .remove-row-button { 
        color: $rusty-red;
        display: inline-block;
        margin: 0 15px;
        font-size: 23px;
        padding: 0;
        background: transparent;
      }
    }
  }  
}

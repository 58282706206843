@import '../../styles/variables';

.feature-flag-experiment-stats {
  .header { 
    margin-bottom: 15px;
    button, h2 { 
      display: inline-block;
    }
    h2 {
      padding-left: 10px;
      margin-top: 0;
    }

    .ui.icon.back-button { 
      font-size: 23px;
      padding: 0;
      background: transparent;
    }
  }

  .feature-flag-experiment-filters {
    margin-top: 10px;
    max-width: 500px;
  }

  .feature-flag-experiment-tiles { 
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
  .feature-experiment-clients {
    margin-top: 10px;
  }
}

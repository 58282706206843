@import '../../styles/variables';

.edit-feature-flag-experiment {
  .header { 
    margin-bottom: 15px;
    button, h2 { 
      display: inline-block;
    }
    h2 {
      padding-left: 10px;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .sub-header { 
      display: block;
      font-size: 18px;
      font-style: italic;
      cursor: pointer;
    }

    .ui.icon.back-button { 
      font-size: 23px;
      padding: 0;
      background: transparent;
    }
  }

  .ffe-body { 
    .subheader-label { 
      font-size: 18px;
      font-weight: bold;
      margin-top: 15px;
      display: block;
    }
    .ffe-field-group { 
      display: flex;
      flex-basis: max-content;
      flex-direction: row;
      gap: 15px;
    }

    .ffe-field-value { 
      margin-bottom: 12px;
      label { 
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin-bottom: 3px;
        color: $onyx;
      }
      .sub-label { 
        font-weight: normal;
        font-style: italic;
      }

      &.ffe-name, &.ffe-segment-id, &.ffe-segment-historical-id  { 
        label + div { 
          min-width: 300px;
        }
      }

      &.ffe-dates {
        
      }

      &.ffe-estimate-size { 
        .input { 
          width: 150px;
        }
      }

      &.ffe-experiment-size, &.ffe-qualifier-skip-size {
        .input { 
          width: 150px;
        }
      }

      &.ffe-subscription-types, &.ffe-feature-flags { 
        label + div { 
          min-width: 200px;
          width: fit-content;
        }
      }
      &.ffe-feature-flags {
        .feature-flags { 
          width: 300px;
        }
        .remove-row-button { 
          color: $rusty-red;
          display: inline-block;
          margin: 0 15px;
          font-size: 23px;
          padding: 0;
          background: transparent;
        }
        .feature-flag-value { 
          .data-type-bool-value, .data-type-int-value, .data-type-decimal-value { 
            width: 150px;
          }
        }
      }
    }

    .form-buttons { 
      text-align: right;
      .ui.negative.button { 
        margin-right: 10px;
      }
    }
  }
}

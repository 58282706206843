@import '../../styles/variables';

.chart {
  position: relative;

  .title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .total-count {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 8px;
  }
}

.pie-chart {
  .top-input {
    width: 50px;
    margin-bottom: 10px;
  }

  .top-label {
    margin-left: 5px;
  }

  .total {
    text-align: center;
  }

  .chart-with-legend {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }

  .chart-canvas {
    margin-top: 15px;
  }

  .legend {
    width: 220px;
    overflow: auto;
    z-index: 1;
  }

  .legend-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color 1s;
  }

  .legend-row:hover {
    background-color: rgba(230, 230, 230, 0.5); //#f0f0f4;
    border-radius: 6px;
  }

  .legend-text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }

  .legend-dot-wrapper {
    width: 20px;
  }

  .legend-dot {
    margin-right: 10px;
    margin-left: 5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}

@import '../../styles/variables';

.delete-add-on-button {
  display: inline-flex !important;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  padding: 0px !important;

  i { 
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
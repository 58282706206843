@import '../../../styles/variables.scss';

.api-configs {
  padding: 16px;

  .form-width {
    width: 75%;
    min-width: 375px;
  }

  .half-width {
    width: 50%;
    min-width: 375px;
  }

  .form-wrapper {
    background-color: white;
    border-radius: 5px;
    border: solid 1px rgba(34, 36, 38, 0.15);
  }
  .header-cell {
    margin: 0;
    margin-bottom: -5px;
    padding: 15px 15px 15px 10px;
  }
  .cell {
    white-space: nowrap;
    padding: 10px;
  }
  .label {
    width: 85px;
  }
  .row-divider {
    border-color: rgba(34, 36, 38, 0.15);
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
  }
}

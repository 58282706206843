@import '../../../styles/variables';

.add-public-forms-subscription-modal {
  .close.icon { 
    top: 3px;
    right: 5px;
  }

  .pfs-body {

    .pfs-field-group { 
      margin-bottom: 12px;

      label { 
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin-bottom: 3px;
        color: $onyx;
      }

      .purchase-link { 
        display: flex;
        flex-basis: max-content;
        flex-direction: row;
        
        .ui.fluid.input { 
          flex-grow: 1;
        }
      }
    }
  }
}
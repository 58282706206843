@import '../../styles/variables';

.add-ons-configuration-table-row {

  .add-ons-configuration-table-row-value { 
   
    &.add-on-recurly-code { 
      width: 15%;
    }

    &.add-on-title { 
      width: 15%;
    }

    &.add-on-description { 
      width: 20%;
    }

    &.add-on-feature-flag { 
      width: 15%;
    }

    &.add-on-pricing-type { 
      width: 10%;
    }
    
    &.add-on-monthly-price { 
      width: 5%;
    }

    &.add-on-yearly-price { 
      width: 5%;
    }

    &.add-on-user-limit { 
      width: 5%;
    }

    &.add-on-unsubscribe { 
      width: 10%;;
      .input {
        cursor: pointer;
      }
    }
   
    &.add-on-metadata {
      i { 
        cursor: pointer;
      }
    }
  }
}
.add-ons-dates { 
  .add-ons-dates-section { 
    padding-bottom: 10px;
    label { 
      font-weight: bold;
      display: block;
    }
    span { 
      display:  block;
    }
  }
}
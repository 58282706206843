@import '../../styles/variables';

.apps {
  padding: 16px;

  .main {
    .app-sidebar {
      .nav-wrapper {
        .nav-link {
          display: block;
          padding: 4px;

          &:hover {
            background: $fresh-air;
          }

          &.active {
            background: $sky-blue;
          }
        }
      }
    }
  }
}

.feature-flag-value {
  div.ui.fluid { 
    &.data-type-bool-value, &.data-type-int-value, &.data-type-decimal-value { 
      width: 150px;
    }
  }

  & textarea.data-type-json-value, & textarea.data-type-string-value {
    width: 400px;
  }
}

@import '../../styles/variables';

.feature-flag-experiment-statistic {
  .ui.card { 
    width: 500px;
    .content { 
      .description { 
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
      }

      .statistic { 
        margin: 0;
      }
    }
  }
}
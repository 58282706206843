@import '../../styles/variables';

.dashboard {
  background: $white-smoke;
  min-height: 100%;

  .dashboard-header {
    padding: 16px 16px 0 16px;
    position: relative;

    h3 {
      margin: 0;
      padding: 0;
    }

    .button-wrapper {
      right: 16px;
      top: 12px;
      position: absolute;
      display: flex;
    }
  }

  .modules-wrapper {
    padding: 16px;

    .module {
      .body {
        min-height: 180px;
      }
    }
  }

  .section {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 2px;
    }
  }
}

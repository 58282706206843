@import '../styles/variables';

.modules {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  &.flex-row {
    flex-direction: row !important;
  }
}

.module {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  min-width: 300px;

  &.fullwidth-module {
    flex: 1;
  }

  h3 {
    line-height: 46px;
    padding: 0 0 0 12px;
    margin: 0px;
    color: $onyx;
    border-bottom: 1px solid $silver;
  }

  .body {
    position: relative;
    padding: 16px;
  }
}

@import '../../styles/variables.scss';

.wf-paging.secondary.menu.ui {
  position: relative;
  .item {
    color: $charcoal;
  }

  .wf-showing-rows {
    margin-right: 20px;
  }

  .paging-text {
    margin: 0 8px 0 0;

    input {
      width: 41px;
      height: 25px;
      text-align: center;
      border: 1px solid #b7c1c6;
      border-radius: 2px;
      margin: 0 3px;
      font-size: 14px;
      font-weight: 400;
      color: $charcoal;
    }
  }
  .paging-button {
    background-repeat: no-repeat;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    margin: 0 2px;
    color: $brand-blue;

    &.paging-previous {
      background-image: url('../../../src/assets/paging-previous.svg');
      background-position: left center;
      padding-left: 13px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    &.paging-next {
      background-image: url('../../../src/assets/paging-next.svg');
      background-position: right center;
      padding-right: 13px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    &:disabled {
      color: $granite;
      cursor: default;
    }
  }
  .paging-separator {
    height: 23px;
    width: 2px;
    background: #b7c1c6;
    float: left;
    font-size: 1px;
    overflow: hidden;
    margin: 0 8px;
  }

  .paging-total-results {
    color: $granite;
    margin-left: 16px;
  }
}

@import '../../styles/variables';

.parent-account {
  padding: 16px;
  background: $white-smoke;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .ui.statistics {
    .statistic {
      .icon {
        margin-right: 8px;
        font-size: 54px;
      }
    }
  }

  .account-info {
    position: relative;
    padding: 16px;
    height: 80px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    min-width: 300px;
    margin-bottom: 16px;

    .company-name {
      margin: 0px;
      padding: 0px;
      color: $onyx;
      font-size: 22px;
    }

    .clientId {
      color: $granite;
    }

    .stats {
      position: absolute;
      right: 16px;
      bottom: 8px;
      display: flex;

      .stat {
        position: relative;
        padding-left: 24px;
        margin-left: 24px;

        .ui.icon {
          position: absolute;
          left: 0px;
          top: 12px;
          font-size: 14px;
        }

        .title {
          font-weight: bold;
          color: $onyx;
        }

        .value {
          color: $charcoal;
        }
      }
    }
  }
}

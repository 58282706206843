.accounts-search {
  .body-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .row {
      padding-top: 12px;
      display: flex;

      label {
        padding-right: 12px;
        width: 150px;
        margin-top: 8px;
      }

      .dropdown {
        width: 300px;

        &.single {
          width: 182px;
        }
      }
      .second-input {
        margin-left: 8px;
      }
    }
    .search {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 16px;
      margin-bottom: 16px;
    }

    .export {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 16px;
      margin-top: 16px;
      background-color: #ff7a47;
    }

    .download {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 116px;
      margin-bottom: 16px;
    }
  }
}

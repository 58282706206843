@import '../../styles/variables';

.dynamic-table { 
  .dynamic-table-title { 
    h2 { 
      margin-bottom: 2px;
    }
    span { 
      font-style: italic;
      display: block;
      margin-bottom: 5px;
    }
    .error { 
      color: $rusty-red;
    }
  }
  .clickable { 
    cursor: pointer;
  }
  .hyperlink { 
    color: $brand-blue;
    &:hover { 
      text-decoration: underline;
    }
  } 
  .dynamic-table-row { 
  }

  .dynamic-table-pagination { 
    &.ui.menu {
      position: relative;
    } 
  }
}
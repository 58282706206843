@import '../../styles/variables';

.account-controls { 
  .ui.radio.checkbox { 
    display: block;
    margin-bottom: 10px;
  }

  .comment { 
    min-width: 600px;
    
  }

  .btn-acount-update { 
    margin-top: 20px;
  }
}
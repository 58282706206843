@import '../../styles/variables';

.workflow-copy {
  padding: 16px;
  background: $white-smoke;
  min-height: 100%;

  .clients-row {
    display: flex;
    flex-direction: row;
    min-height: 125px;
  }

  .from-client {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-right: 25px;
  }

  .same-account {
    padding-bottom: 10px;
  }

  .client-container {
    min-height: 105px;
  }

  .client-width {
    width: 370px;
  }

  .client-label {
    padding-left: 5px;
  }

  .disabled-label {
    color: silver;
  }

  .client-name {
    font-size: 17px;
    padding-top: 5px;
    padding-left: 5px;
  }

  .error {
    color: red;
    padding-top: 5px;
    padding-left: 5px;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }

  .workflow-item {
    padding-bottom: 2px;
  }

  .workflow-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .workflow-id {
    padding-right: 5px;
  }

  .workflow-name {
    width: 100%;
  }

  .workflow-error {
    padding-left: 25px;
  }

  .status-icon {
    height: 20px;
    width: 20px;
    padding-right: 5px;
  }

  .hand {
    cursor: pointer;
  }
}

@import '../../styles/variables';

.azure-devops-table {

  .azure-devops-table-header { 
    display: flex;
    flex-direction: row;

    .ui.right.labeled.button {
      button {
        font-weight: 700 !important;
      }
      a.label { 
        color: $charcoal !important;
      }
      &.deploy-beta-button { 
        button { 
          color: $charcoal !important;
          background-color: $sky-blue !important;
        }
        &:hover {
          button { 
            color: $charcoal !important;
            background-color: $sky-blue !important;
          }
        }
      }
      &.deploy-production-button { 
        button { 
          color: $white !important;
          background-color: $atomic-orange !important;
        }
        &:hover {
          button { 
            color: $white !important;
            background-color: $atomic-orange !important;
          }
        }
      }
      
    }

    .deployment-count {
      margin-left: auto;
    }
  }
 
 
  table {
    margin: 0 10px 10px 10px;
    th { 
      font-size: 20px;
    }

    td { 
      .release-name { 
        font-size: 18px;
      }
      .release-environment-cell { 
        display: flex;
        flex-direction: row;
      }
    }
  }
}
.workflows {
  .toggle-wrapper {
    display: flex;
    padding-top: 10px;
    span {
      padding-left: 8px;
    }
  }
  .copy {
    cursor: pointer;
  }
}

@import '../../../../../styles/variables.scss';

.onboarding-status {
  .onboarding-status-info {
    margin-bottom: 1rem;
  }

  .status-node {
    margin-bottom: 1rem;

    h4 {
      margin-bottom: 0.25rem;
    }

    .status-node-marker {
      margin: 0.75rem 0;

      .complete-marker {
        height: 30px;
        width: 30px;
        display: inline-block;
        vertical-align: middle;
        background-color: $silver;
        border-radius: 50%;
        margin: 0 0.5rem;

        &.checked {
          background-color: $caribbean-green;
        }
      }
    }
  }
}

@import '../../styles/variables.scss';

.ui.button.cancel {
  background-color: transparent;
  color: $brand-blue;
  font-weight: 200;
}

.ui.button.primary {
  background-color: $atomic-tangerine;
  color: white;
}
@import '../../styles/variables';

.subscription-features {
  min-height: 200px;
  .feature-category {
    display: flex;
    flex-direction: column;

    .feature-row {
      margin: 0px;
      padding: 12px;
      border-bottom: .5px solid rgba(34,36,38,.15);

      &.feature-flag {
        cursor: pointer;

        .json-value-component {
          display: flex;
          align-items: center;
          
          textarea.text-area-json {
            margin-left: 50px;
            width: 400px;
            height: 150px;
            cursor: pointer;
          }
        }

        .string-value-component {
          display: flex;
          align-items: center;
          
          textarea.text-area-string {
            margin-left: 50px;
            width: 300px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
      
      .ui.disabled.checkbox label {
        cursor: pointer !important;
      }

      .text-area-overlay {
        position: relative;
      }

      .text-area-overlay::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
@import '../../../styles/variables';

.email-activity {
  padding: 16px;

  tr.activity-row {
    cursor: pointer;

    &:hover {
      background-color: $white-smoke;
    }
  }
}

.email-status-wrapper {
  display: flex;
  align-items: center;

  .status-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: $granite;

    &.requested {
      background-color: $sunglow;
    }
    &.processed {
      background-color: $atomic-tangerine;
    }
    &.deferred {
      background-color: $atomic-tangerine;
    }
    &.dropped, &.bounce, &.blocked, &.smtpfailure, &.error &.badrecipients, &.invalidrecipient, &.validationerror {
      background-color: $rusty-red;
    }
    &.delivered {
      background-color: $caribbean-green;
    }
  }
}
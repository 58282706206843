@import '../../styles/variables.scss';
@import '../../../node_modules/react-day-picker/lib/style.css';

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $brand-blue;
}

.DayPicker {
  display: block;
  .ui.fluid.dropdown {
    font-size: 11px;
  }
  .DayPicker-wrapper {
    outline: none;
  }
}
.DayPicker-Month,
.DayPicker-Months {
  width: 100%;
}

.DayPicker-Caption {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  .ui.fluid.dropdown {
    font-size: 13px;
    font-family: $font-family;
  }
}

.DayPicker-Dropdown {
  padding: 0em !important;
}

.DayPicker-Day {
  border-radius: 0%;
  outline: none;
}

.DayPicker-NavBar {
  margin-top: 10px;
}

.DayPicker-NavBar button {
  margin-top: 10px;
  border: 0px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-family;
  color: $charcoal;
  background-color: white;
}

.ui.grid.container {
  .ui.fluid.dropdown {
    font-size: 14px;
    font-family: $font-family;
  }
}

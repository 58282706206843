@import './variables';

.link-button {
  /* resets */
  font-family: $font-family;
  font-size: 14px;
  color: $text-link;
  border: none;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover {
    border-bottom: 1px solid $text-link;
  }

  &:disabled {
    opacity: 0.7;

    &:hover {
      border-bottom: 1px solid transparent;
      cursor: default;
    }
  }

  &.small {
    font-size: 12px;
  }
}

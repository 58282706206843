.layout {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .nav-menu.ui.menu {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
  }

  .layout-body {
    position: relative;
    flex: 1;
    height: 1px;
  }
}

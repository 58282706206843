@import '../../styles/variables';

.account-metrics {
  padding: 16px;
  background: $white-smoke;
  min-height: 100%;

  .control-bar {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
  }

  .controls {
    max-width: 500px;
    display: flex;
    flex-direction: row;
  }

  .control {
    margin-right: 20px;
  }

  .charts {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .module {
    margin-bottom: 10px;
  }
}

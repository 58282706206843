$font-family: 'Nunito Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$text-link: #2b9abf;

/* Colors */
$fresh-air: #eefafe;
$sky-blue: #d9edf3;
$brand-blue: #0b99c2;
$slate: #455a64;
$atomic-orange: #ff7a47;
$white: #fff;

$onyx: #333;
$charcoal: #636363;
$granite: #838c90;
$pewter-blue: #7b99a1;
$light-slate: #b6c1c6;
$silver: #e8ebec;
$platinum: #f3f3f3;
$white-smoke: #f9f9f9;

$rusty-red: #d24d57;
$atomic-tangerine: #ff7a47;
$sunglow: #f7da61;
$caribbean-green: #29d6c7;

$rusty-red-40: rgba($rusty-red, 0.4);
$atomic-tangerine-40: rgba($atomic-tangerine, 0.4);
$sunglow-40: rgba($sunglow, 0.4);
$caribbean-green-40: rgba($caribbean-green, 0.4);

@import '../../../styles/variables';

.email-activity-events {


  .loading-wrapper {
    position: relative;
    height: 32px;
  }
}

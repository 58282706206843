.feature-flags-list {
  padding: 16px;

  .page-header { 
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    .ui.search>.results {
      width: fit-content;
    }
    .header-item { 
      flex-grow: 1;

      .ui.search > .ui.input { 
        width: 100%
      }
    }
  }
  .ui.table {
    tr { 
      cursor: pointer;
    }
    
    td { 
      &.group-select-column {
        padding: 0px;
        font-size: 12px;

        .ui.fluid.multiple.dropdown {
          padding-right: 8px;
        }
      }
      &.flag-name { 
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.flag-description { 
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
